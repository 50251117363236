import { render, staticRenderFns } from "./activity.vue?vue&type=template&id=38eddc52"
import script from "./activity.vue?vue&type=script&lang=js"
export * from "./activity.vue?vue&type=script&lang=js"
import style0 from "./activity.vue?vue&type=style&index=0&id=38eddc52&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports