<template>
	<div>
		<div class="wrapper bg-white" style="padding:0 30px;box-sizing: border-box;">
			<Breadcrumb :crumbsList="pathList"></Breadcrumb>
			<div style="padding:40px 0;">
				<el-row :gutter="20">
				  <el-col :span="12">
					  <div >
						  <div class="base-font-36">首届腾冲科学大奖提名指南</div>
						  <div style="height:240px;line-height:1.7;">
							  <div class="ellipsis7 base-font-16 margin-top-15">根据《关于设立腾冲科学大奖的倡议》和《腾冲科学大奖评选办法》，为帮助提名人、被提名人了解首届“腾冲科学大奖”提名条件、申报条件、流程与注意事项等，特制定本指南。根据《关于设立腾冲科学大奖的倡议》和《腾冲科学大奖评选办法》，为帮助提名人、被提名人了解首届“腾冲科学大奖”提名条件、申报条件、流程与注意事项等，特制定本指南。根据《关于设立腾冲科学大奖的倡议》和《腾冲科学大奖评选办法》，根据《关于设立腾冲科学大奖的倡议》和《腾冲科学大奖评选办法》，为帮助提名人、被提名人了解首届“腾冲科学大奖”提名条件、申报条件、流程与注意事项等，特制定本指南。根据《关于设立腾冲科学大奖的倡议》和《腾冲科学大奖评选办法》，为帮助提名人、被提名人了解首届“腾冲科学大奖”提名条件、申报条件、流程与注意事项等，特制定本指南。为帮助提名人、被提名人了解首届“腾冲科学大奖”提名条件、申报条件、流程与注意事项等，特制定本指南。</div>
						  </div>
						  <div class="base-font-16 text-blue">+查看更多</div>
					  </div>
				  </el-col>
				  <el-col :span="12">
					  <div style="text-align:right;">
						  <el-image
						       style="width:308px; height:335px"
						       :src="logo"
						       fit="contain"></el-image>
					  </div>
					   
				  </el-col>
				</el-row>
				<div style="padding-top:40px;">
					<div class="bmimgBox">
						<div class="mainBox text-center text-white">
							<div class="flex align-center justify-center" style="height:400px;">
								<div>
									<div class="base-font-26 text-center">
										提名材料递交方式
									</div>
									<div class="base-font-16 text-left margin-top-15">
										<div>提名材料通过电子邮箱tsf2023@126.com或腾冲科学家论坛官网http://www.tcsf.com.cn上传提交</div>
										<div class="margin-top-10">报名截止时间: 2023年8月15日24时(GMT+8 )</div>
									</div>
									<div class="bmBtn text-bold base-font-24" @click="gobm">立即报名</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import Breadcrumb from '../../components/breadcrumbs.vue'
	import {
		getWzContentDetail,
		menuListById
	} from "@/api/api";
	export default {
		components: {
			Breadcrumb
		},
		data() {
			return {
				menuId: '',
				id: '',
				logo: require('@/assets/image/hdlogo.png'),
			}
		},
		mounted() {

		},
		methods: {
			gobm(id) {
			  this.$router.push({path: '/bm'});
			},
		}
	}
</script>

<style>
	.newsLable span {
		padding: 0 20px;
	}

	.newsLable span+span {
		border-left: 1px solid #dddddd;
		line-height: 1
	}

	.newsAbstract {
		background: #f0f5f8;
		padding: 50px 60px;
		box-sizing: border-box;
		line-height: 2.1;
	}

	.newsContent {
		line-height: 2.1;
		padding: 0 0 50px 0;
	}
	.bmimgBox{width:100%;height:400px;background: url('../../assets/image/cn.png') center no-repeat;position:relative;
  background-size: 100% 100%;}
  .bmimgBox .mainBox{width:100%;height:100%;background:rgba(0,0,0,0.6);position:absolute;top:0;left:0}
  .bmBtn{width:170px;height:52px;border-radius:29px;border:2px solid #FFFFFF;line-height:53px;margin:50px auto;cursor:pointer;}
  .bmBtn:hover{background:#FFFFFF;color:#924574;}
</style>